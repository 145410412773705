
import axios from 'axios';
import { defineComponent, onMounted } from 'vue';
import { Invoice, invoiceSchema, PaymentStatus } from '../types';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
    useForm,
    useIsFormDirty,
    useIsFormValid,
    useValidateForm,
} from 'vee-validate';

export default defineComponent({
    setup() {
        const apiAddress = process.env.VUE_APP_API_URL || '/api';

        const router = useRouter();
        const route = useRoute();
        const store = useStore();

        const generateInvNo = function () {
            const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            return (
                letters.charAt(Math.floor(Math.random() * letters.length)) +
                Math.floor(Math.random() * 99999)
            );
        };

        const initialValues = {
            invoiceNo: generateInvNo(),
            fromAddress: '',
            fromCity: '',
            fromPostcode: '',
            fromCountry: '',
            clientName: '',
            clientEmail: '',
            clientAddress: '',
            clientCity: '',
            clientPostcode: '',
            clientCountry: '',
            invoiceDate: Date(),
            paymentTerms: 30,
            projectDescription: '',
            itemList: [
                {
                    itemName: '',
                    quantity: 1,
                    price: 0.0,
                },
            ],
            paymentStatus: 'unpaid' as PaymentStatus,
        };

        onMounted(() => {
            if (route.query.invoiceNo) {
                axios
                    .get(
                        `${apiAddress}/invoice?invoiceNo=${route.query.invoiceNo}`
                    )
                    .then((res) => {
                        Object.assign(form, res.data);
                        validate();
                    })
                    .catch((err) =>
                        store.dispatch(
                            'notification',
                            'Error loading invoice: ' + err
                        )
                    );
            }
        });

        const {
            errors,
            handleSubmit,
            isSubmitting,
            values: form,
        } = useForm<Invoice>({
            validateOnMount: false,
            validationSchema: invoiceSchema,
            initialValues,
        });

        const dateFormat = function () {
            const date = new Date(form.invoiceDate);

            const day = ('0' + date.getDate()).slice(-2);
            const month = ('0' + (date.getMonth() + 1)).slice(-2);

            return date.getFullYear() + '-' + month + '-' + day;
        };

        const back = function () {
            router.back();
        };

        const onSubmit = handleSubmit((form) => {
            if (route.query.invoiceNo) {
                axios
                    .put(
                        `${apiAddress}/invoice?invoiceNo=${route.query.invoiceNo}`,
                        form
                    )
                    .then(() => router.push('/'))
                    .catch((err) =>
                        store.dispatch(
                            'notification',
                            'Error updating invoice: ' + err
                        )
                    );
            } else {
                axios
                    .post(`${apiAddress}/invoice`, form)
                    .then(() => router.push('/'))
                    .catch((err) => {
                        console.error(`${apiAddress}/invoice`);
                        store.dispatch(
                            'notification',
                            'Error inserting new invoice: ' + err
                        );
                    });
            }
        });

        const addNewItem = function () {
            if (form.itemList)
                form.itemList.push({
                    itemName: '',
                    quantity: 1,
                    price: 0,
                });
        };

        const delLine = function (index: number) {
            if (form.itemList) form.itemList.splice(index, 1);
        };

        const dateChange = function (event: InputEvent) {
            const dateField = event.target as HTMLInputElement;
            const newDate = new Date(dateField.value);
            form.invoiceDate = newDate.toISOString();
        };

        const isFormValid = useIsFormValid();
        const isFormDirty = useIsFormDirty();
        const validate = useValidateForm();

        return {
            back,
            generateInvNo,
            onSubmit,
            form,
            addNewItem,
            delLine,
            dateChange,
            dateFormat,
            isSubmitting,
            isFormDirty,
            isFormValid,
            errors,
            validate,
        };
    },
});
