
import { defineComponent, ref, onMounted } from 'vue';

import axios from 'axios';
import { Wishlist } from '../types';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const apiAddress = process.env.VUE_APP_API_URL || '/api';

export default defineComponent({
    setup() {
        const wishlist = ref([] as Array<Wishlist>);
        const order = ref('');
        const route = useRoute();
        const store = useStore();

        const getTotal = function () {
            return wishlist.value.reduce(
                (cumm, val) => (cumm += val.quantity * val.price),
                0
            );
        };

        const getWishlist = function () {
            axios
                .get(`${apiAddress}/wishlist?name=${route.query.name}`)
                .then((res) => {
                    wishlist.value = res.data;
                })
                .catch((err) =>
                    store.dispatch(
                        'notification',
                        'Error loading wishlist: ' + err
                    )
                );
        };

        const sortByPrice = function () {
            order.value === '' || order.value === 'asc'
                ? (order.value = 'des')
                : (order.value = 'asc');
            wishlist.value = wishlist.value.sort((a, b) =>
                order.value === 'des' ? b.price - a.price : a.price - b.price
            );
        };

        onMounted(getWishlist);

        return {
            wishlist,
            getTotal,
            sortByPrice,
            order,
        };
    },
});
