
import { defineComponent, onMounted, ref } from 'vue';

import axios from 'axios';
import InvoiceCard from '../components/InvoiceCard.vue';
import Filters from '../components/Filters.vue';
import { Invoice } from '../types';
import Pagination from '../components/Pagination.vue';
import { useStore } from 'vuex';

const apiAddress = process.env.VUE_APP_API_URL || '/api';

export default defineComponent({
    setup() {
        const store = useStore();
        const allInvoices = ref([] as Invoice[]);
        const invoices = ref([] as Invoice[]);
        const totalPages = ref(0);
        const currentPage = ref(1);

        const getInvoices = () => {
            axios
                .get(`${apiAddress}/invoices?page=${currentPage.value}`)
                .then((res) => {
                    totalPages.value = Number(res.data.totalPages);
                    currentPage.value = Number(res.data.currentPage);
                    allInvoices.value = res.data.invoices;
                    invoices.value = [...allInvoices.value];
                })
                .catch((err) =>
                    store.dispatch(
                        'notification',
                        'Error loading invoices: ' + err
                    )
                );
        };

        const invoiceTotal = (invoice: Invoice) =>
            invoice.itemList.reduce(
                (total, curr) => total + curr.quantity * curr.price,
                0
            );

        const sortByPrice = function () {
            store.dispatch('notification', 'Sorting by Price...');
            invoices.value.sort((a, b) => invoiceTotal(b) - invoiceTotal(a));
        };

        onMounted(getInvoices);

        const filter = function (term: 'all' | Invoice['paymentStatus']) {
            if (term === 'all') {
                invoices.value = [...allInvoices.value];
            } else {
                invoices.value = allInvoices.value.filter(
                    (inv) => inv.paymentStatus === term
                );
            }
        };

        const pageChange = (pageNo: number) => {
            currentPage.value = pageNo;
            getInvoices();
        };

        return {
            invoices,
            filter,
            allInvoices,
            sortByPrice,
            totalPages,
            currentPage,
            pageChange,
        };
    },
    components: {
        InvoiceCard,
        Filters,
        Pagination,
    },
});
