
import { computed, defineComponent } from 'vue';

import { useStore } from 'vuex';

export default defineComponent({
    setup() {
        const store = useStore();
        const message = computed(() => store.state.notificationText);
        const visible = computed(() => store.state.notificationVisible);
        return { visible, message };
    },
});
