
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import Navigation from './components/Navigation.vue';
import Notification from './components/Notification.vue';

export default defineComponent({
    setup() {
        const router = useRouter();
        const transitionName = ref('');

        watch(router.currentRoute, (curr, prev) => {
            const routes = router.getRoutes();
            const currInd = routes.findIndex(
                (route) => route.path === curr.path
            );
            const prevInd = routes.findIndex(
                (route) => route.path === prev.path
            );
            transitionName.value =
                currInd < prevInd ? 'slide-right' : 'slide-left';
        });

        return {
            transitionName,
        };
    },
    components: {
        Navigation,
        Notification,
    },
});
