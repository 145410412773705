
import { defineComponent, onMounted, ref } from 'vue';

import axios from 'axios';

const apiAddress = process.env.VUE_APP_API_URL || '/api';

export default defineComponent({
    setup() {
        type wishlistType = { wishlistName: string; qty: number };
        const wishlists = ref([] as Array<wishlistType>);
        const showErr = ref(false);

        const getwishlists = function () {
            axios
                .get(`${apiAddress}/wishlists`)
                .then((res) => {
                    wishlists.value = res.data.map(
                        (line: { _id: string; count: number }) => {
                            return { wishlistName: line._id, qty: line.count };
                        }
                    );
                })
                .catch((err) => console.error(err));
        };

        onMounted(getwishlists);

        return { wishlists, showErr };
    },
});
