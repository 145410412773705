
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import { Wishlist } from '../types';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

const apiAddress = process.env.VUE_APP_API_URL || '/api';

export default defineComponent({
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();

        const form = ref({
            itemName: '',
            link: '',
            imageUrl: '',
            quantity: 1,
            price: 0.0,
            dateAdded: new Date(),
            description: '',
            wishlistName: '',
        } as Wishlist);

        const back = function () {
            router.back();
        };

        const submitForm = function () {
            if (route.query.id) {
                axios
                    .put(
                        `${apiAddress}/wishlist?id=${form.value._id}`,
                        form.value
                    )
                    .then(() => router.push('/wishlists'))
                    .catch((err) =>
                        store.dispatch(
                            'notification',
                            'Error updating wishlist: ' + err
                        )
                    );
            } else {
                axios
                    .post(`${apiAddress}/wishlist`, form.value)
                    .then(() => router.push('/wishlists'))
                    .catch((err) =>
                        store.dispatch(
                            'notification',
                            'Error inserting new wishlist: ' + err
                        )
                    );
            }
        };

        return {
            back,
            submitForm,
            form,
        };
    },
    created() {
        if (this.$route.query.id) {
            axios
                .get(`${apiAddress}/wishlist?id=${this.$route.query.id}`)
                .then((res) => {
                    this.form = res.data;
                })
                .catch((err) => console.error(err));
        }
    },
});
