
import axios from 'axios';
import { defineComponent, onMounted, ref } from 'vue';
import { Wishlist } from '../types';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

const apiAddress = process.env.VUE_APP_API_URL || '/api';

export default defineComponent({
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();

        const wishlistName = ref(route.query.id || '');
        const list = ref(
            Array.of(
                {
                    itemName: '',
                    quantity: 1,
                    price: 0,
                },
                {
                    itemName: '',
                    quantity: 1,
                    price: 0,
                },
                {
                    itemName: '',
                    quantity: 1,
                    price: 0,
                }
            ) as Array<Wishlist>
        );

        const back = function () {
            router.back();
        };

        const submitForm = function () {
            if (route.query.id) {
                // send edit req
            } else {
                const listSubmit = list.value.filter((a) => a.itemName !== '');
                listSubmit.forEach(
                    (item) => (item.wishlistName = '' + wishlistName.value)
                );
            }
        };

        onMounted(() => {
            if (route.query.id) {
                axios
                    .get(`${apiAddress}/wishlist?id=${route.query.id}`)
                    .then((res) => {
                        list.value = res.data;
                    })
                    .catch((err) =>
                        store.dispatch(
                            'notification',
                            'Error loading wishlist: ' + err
                        )
                    );
            }
        });

        return {
            wishlistName,
            back,
            submitForm,
            list,
        };
    },
});
