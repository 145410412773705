
import { defineComponent, ref } from 'vue';

export default defineComponent({
    emits: ['selected'],
    setup() {
        const selected = ref('');
        const statusOptions = ref([
            'all',
            'paid',
            'unpaid',
            'pending',
            'draft',
        ]);
        return { statusOptions, selected };
    },
});
