
import { defineComponent, ref, onMounted } from 'vue';

import axios from 'axios';
import { Invoice } from '../types';
import Status from '../components/Status.vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

const apiAddress = process.env.VUE_APP_API_URL || '/api';

export default defineComponent({
    setup() {
        const invoice = ref({} as Invoice);
        const router = useRouter();
        const route = useRoute();
        const store = useStore();

        const getInvoice = function () {
            axios
                .get(`${apiAddress}/invoice?invoiceNo=${route.query.invoiceNo}`)
                .then((res) => {
                    invoice.value = res.data;
                })
                .catch((err) =>
                    store.dispatch(
                        'notification',
                        'Error loading invoice: ' + err
                    )
                );
        };

        onMounted(getInvoice);

        const formatDate = function (date: string | Date) {
            const months = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ];
            const formattedDate = date instanceof Date ? date : new Date(date);
            return `${formattedDate.getDate()} ${
                months[formattedDate.getMonth()]
            } ${formattedDate.getFullYear()}`;
        };

        const dueDate = function () {
            const dateDue = new Date(invoice.value.invoiceDate);
            dateDue.setDate(dateDue.getDate() + invoice.value.paymentTerms);
            return formatDate(dateDue);
        };

        const formatPrice = function (price: number) {
            return `£${price.toFixed(2)}`;
        };

        const invoiceTotal = function () {
            if (!invoice.value.itemList) return formatPrice(0);
            return formatPrice(
                invoice.value.itemList.reduce(
                    (total, line) => line.quantity * line.price + total,
                    0
                )
            );
        };

        const markPaid = function () {
            axios
                .patch(
                    `${apiAddress}/invoice?invoiceNo=${invoice.value.invoiceNo}`,
                    { paymentStatus: 'paid' }
                )
                .then((res) => {
                    invoice.value = res.data;
                    store.dispatch('notification', 'Payment status updated');
                })
                .catch((err) =>
                    store.dispatch(
                        'notification',
                        'Error updating status: ' + err
                    )
                );
        };

        const deleteInv = function () {
            axios
                .delete(
                    `${apiAddress}/invoice?invoiceNo=${invoice.value.invoiceNo}`
                )
                .then(() => {
                    router.push('/');
                    store.dispatch('notification', 'Invoice Deleted');
                })
                .catch((err) =>
                    store.dispatch(
                        'notification',
                        'Error deleting invoice: ' + err
                    )
                );
        };

        const editInv = function () {
            router.push({
                path: '/editInvoice',
                query: { invoiceNo: invoice.value.invoiceNo },
            });
        };

        return {
            invoice,
            formatDate,
            dueDate,
            formatPrice,
            invoiceTotal,
            markPaid,
            deleteInv,
            editInv,
        };
    },
    components: {
        Status,
    },
});
