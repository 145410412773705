
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        status: {
            validator(value: string) {
                return (
                    ['paid', 'unpaid', 'pending', 'draft'].indexOf(value) !== -1
                );
            },
            default: 'unpaid',
        },
    },
});
