
import { defineComponent, PropType } from 'vue';
import { Invoice, InvoiceLine } from '../types';
import Status from '../components/Status.vue';

export default defineComponent({
    props: {
        invoice: {
            type: Object as PropType<Invoice>,
            required: true,
        },
    },
    components: {
        Status,
    },
    setup(props) {
        const formattedAmount = function () {
            const itemList = props.invoice.itemList as InvoiceLine[];
            const total = itemList.reduce((acc: number, line) => {
                return line.quantity * line.price + acc;
            }, 0);

            return `£${total.toFixed(2)}`;
        };

        const formattedDuedate = function () {
            const months = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ];
            const dateDue = new Date(props.invoice.invoiceDate);
            dateDue.setDate(dateDue.getDate() + props.invoice.paymentTerms);
            return `Due ${dateDue.getDate()} ${
                months[dateDue.getMonth()]
            } ${dateDue.getFullYear()}`;
        };

        const status = function () {
            switch (props.invoice.paymentStatus) {
                case 'paid':
                    return 'paid';
                case 'unpaid':
                    return 'unpaid';
                case 'pending':
                    return 'pending';
                case 'draft':
                    return 'draft';
                default:
                    return '';
            }
        };

        return { formattedAmount, formattedDuedate, status };
    },
});
